/* Hide scrollbar for Chrome, Safari and Opera */
.scroll_table::-webkit-scrollbar {
  size: 2px;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.scroll_table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

table th, table td{
  white-space: nowrap !important;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 